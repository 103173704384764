ion-title {
    text-align: center;
    font-size: 18px;
}

ion-content {
    --ion-background-color: #f2f3f5;

}

ion-button {
    &[expand='block'] {
        --border-radius: 24px;
    }

    text-transform: none;
    height: 46px;
    font-size: 16px;

}
ion-toolbar {
    --background: white;
}

ion-footer {
    position: absolute;
    bottom: 0;

    ion-toolbar {
        padding: 10px;

        &:last-of-type {
            padding-bottom: 16px;
        }
    }

    ion-button[expand='block'] {
        margin: 16px;
    }
}
ion-segment-button {
    font-size: 14px;
}

ion-icon:not([size]) {
    width: 20px;
    height: 20px;
}
