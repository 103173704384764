.a-anchor-link {
  font-family: $font-family-IBM-Plex-Sans;
  font-weight: $font-weight-medium;
  font-size: 16px;
  color: $color-blue;

  &--grey {
    color: $color-medium-grey
  }

  &--white {
    color: $color-white
  }

  &--hasBackground {
    background-color: $color-medium-grey;
  }

  &--isCentered {
    text-align: center;
  }
}
