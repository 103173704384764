/** Fonts **/

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,500;0,600;1,100&display=swap');
$font-family-IBM-Plex-Sans: 'IBM Plex Sans', sans-serif;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semiBold: 600;
$font-weight-thin: 100;


/** colors **/

$color-orange: #ff6d03;
$color-blue: #005470;
$color-green: #1cad34;
$color-red: #eb3838;
$color-light-grey: #f2f3f5;
$color-medium-light-grey: #e6e7eb;
$color-medium-grey: #8a8f99;
$color-dark-grey: #292c33;
$color-white: #ffffff;

/** border-radius **/

$border-radius: 24px;
$border-radius-images: 12px;

/** box-shadow **/
$box-shadow-light: 0 0 1px 0 rgba(41, 44, 51, 0.32), 0 1px 1px 0 rgba(41, 44, 51, 0.04), 0 2px 2px 0 rgba(41, 44, 51, 0.04), 0 4px 4px 0 rgba(41, 44, 51, 0.04), 0 8px 8px 0 rgba(41, 44, 51, 0.04);
$box-shadow-dark: 0 0 1px 0 rgba(41, 48, 51, 0.32), 0 1px 1px 0 rgba(41, 48, 51, 0.08), 0 2px 2px 0 rgba(41, 48, 51, 0.08), 0 4px 4px 0 rgba(41, 48, 51, 0.08), 0 8px 8px 0 rgba(41, 48, 51, 0.08);

/** Ionic CSS Variables **/
:root {
    --color-orange: #ff6d03;
    --color-blue: #005470;
    --color-green: #1cad34;
    --color-red: #eb3838;
    --color-light-grey: #f2f3f5;
    --color-medium-light-grey: #e6e7eb;
    --color-medium-grey: #8a8f99;
    --color-dark-grey: #292c33;
    --color-white: #ffffff;

    --ion-font-family:  'IBM Plex Sans', sans-serif;
    --ion-toolbar-background: #f7f7f7;
    --color-label: #8a8f99;
    --color-input: #292c33;
    --color-input-background: #e6e7eb;
    --ion-color-icon-white: #ffffff;


    --ion-color-primary: #ff6d03;
    --ion-color-primary-rgb: 255,109,3;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 0,0,0;
    --ion-color-primary-shade: #f6a468;
    --ion-color-primary-tint: #fad5bb;

    --ion-color-secondary: #005470;
    --ion-color-secondary-rgb: 0,84,112;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #004a63;
    --ion-color-secondary-tint: #1a657e;

    --ion-color-tertiary:  #8a8f99;
    --ion-color-tertiary-rgb: 138,143,153;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 0,0,0;
    --ion-color-tertiary-shade: #797e87;
    --ion-color-tertiary-tint: #969aa3;

    --ion-color-success: #1cad34;
    --ion-color-success-rgb: 28,173,52;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255,255,255;
    --ion-color-success-shade: #19982e;
    --ion-color-success-tint: #33b548;

    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255,196,9;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    --ion-color-danger: #eb3838;
    --ion-color-danger-rgb: 235,56,56;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #cf3131;
    --ion-color-danger-tint: #ed4c4c;

    --ion-color-medium: #f2f3f5;
    --ion-color-medium-rgb: 242,243,245;
    --ion-color-medium-contrast: #005470;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #d5d6d8;
    --ion-color-medium-tint: #f3f4f6;

    --ion-color-light: #ffffff;
    --ion-color-light-rgb: 255,255,255;
    --ion-color-light-contrast: #005470;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #e0e0e0;
    --ion-color-light-tint: #ffffff;

}

