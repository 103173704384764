.a-input {
  border-radius: $border-radius;
  border: none;
  background-color: #e6e7eb;
  padding: 11px 24px;
  color: $color-dark-grey;
  font-size: 16px;
  font-family: $font-family-IBM-Plex-Sans;
  font-weight: $font-weight-regular;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  min-width: max-content;

  &--secondary {
    padding: 0;
    border-radius: unset;
    background: none;
    color: $color-blue;
  }
}

.a-input__select-wrapper {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  width: min-content;

  select {
    padding-right: 34px;
  }


  &::after {
    font-family: 'primeicons';
    color: $color-dark-grey;
    content: "\e902";
    font-size: 10px;
    top: 17px;
    right: 19px;
    position: absolute;
  }

  &--secondary {

    select {
      padding-right: 15px;
    }

    &::after {
      color: $color-blue;
      top: 5px;
      right: 0px;
    }
  }
}

.a-input-number {
  ::ng-deep .p-button {
    background: $color-white;
    border: none;
    box-shadow: $box-shadow-dark
  }
  ::ng-deep .p-button-icon {
    color: $color-blue;

  }
  ::ng-deep .p-inputtext {
    border: none;
    font-family: $font-family-IBM-Plex-Sans;
    color: $color-dark-grey;
    text-align: center;
    font-size: 18px;
    width: 40px;
    padding: 0;
  }
  ::ng-deep .p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-up {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
  ::ng-deep .p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-down {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.p-inputtext:enabled:hover {
  border: none;
}
.p-inputtext:enabled:focus {
  box-shadow: none;
}

