/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./variables";
@import "ftable/ftable-main";
@import "~primeicons/primeicons.css";
@import "~primeng/resources/themes/lara-light-indigo/theme.css";
@import "~primeng/resources/primeng.min.css";
@import "./settings/spacing-units";

@import "./mixins.scss";
@import "./form.scss";
@import "./ionic-component.scss";
@import "./primeng-component";

html {
    background: white !important;
}

body {
    padding: 0;
    margin: 0;
}

h1 {
    font-size: 24px;
}
h2 {
    font-size: 22px;
}
h3 {
    font-size: 20px;
}

.u-container {
    padding-left: 16px;
    padding-right: 16px;
}
.footer {
    border-top: 1px solid #e6e7eb;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    background: #f7f7f7;
}

label {
    margin: 16px 0 4px 0;
}
.dropdown {
    display: block;
    padding: 0;

    ::ng-deep .p-dropdown {
        width: 100%;
        background: none;
        border: none;

        .p-element {
            width: 100%;
        }

        .p-inputtext {
            font-family: 'IBM Plex Sans', sans-serif;
            padding: 11px 24px;
        }

        .p-hidden-accessible {
            display: none;
        }

        .p-dropdown-items-wrapper {
            overflow: auto;
        }

        .p-dropdown-item {
            font-family: $font-family-IBM-Plex-Sans;
        }

        .p-dropdown-items .p-dropdown-item.p-highlight {
            color: $color-blue;
            background: $color-light-grey;
        }
    }
}
.version {
    text-align: center;
    font-size: 11px;
    color: #8a8f99;
}
.upgrade-info {
    width: 100%;
    height: 80vh;
}
.update-popup {
    color: var(--color-input);
}
.input-item {
    display: flex;
    margin: 0.5rem 0;

    > label {
        width: 30%;
    }

    > div {
        width: 70%;
    }
}
.alert-button-group {
    justify-content: space-between !important;

    .alert-button {
        &.secondary {
            color: gray;
        }
    }
}
.images-container {
    p {
        width: 70% !important;
        margin-bottom: -36px !important;
    }
}
