select:focus, textarea:focus, input:focus{
    outline: none;
}

input,
label {
    display: block;
}
label {
    margin: 16px 0 4px 8px;
}
.validation-errors {
    font-size: 14px;
    line-height: 20px;
    color: #eb3838;
    margin: 8px 0 4px 8px;

}
form {
    .form-item {
        margin-bottom: 0;

        label {
            display: block;
            font-size: 16px;
            line-height: 24px;
        }
        input, textarea, select, p-dropdown {
            display: block;
            width: 100%;
            border-radius: 24px;
            background: #e6e7eb;
            padding: 0 16px;
            border: solid 1px transparent;
            font-size: 16px;
            line-height: 24px;
            color: var(--color-input);
        }
        input, select {
            height: 48px;
        }

        .validation-errors {
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
            color: var(--ion-color-danger);

            ul {
                margin-top: 0;
            }
        }
    }
}

.dropdown {
    display: block;
    padding: 0;
    width: 100%;

    ::ng-deep .p-dropdown {
        width: 100%;
        background: none;
        border: none;

        .p-element {
            width: 100%;
        }

        .p-hidden-accessible {
            display: none;
        }

        .p-dropdown-items-wrapper {
            overflow: auto;
        }

        .p-dropdown-item {
            font-family: $font-family-IBM-Plex-Sans;
        }

        .p-dropdown-items .p-dropdown-item.p-highlight {
            color: $color-blue;
            background: $color-light-grey;
        }
    }
}
.password-icon {
    position: relative;
    ion-icon {
        position: absolute;
        top: 14px;
        right: 16px;
    }
}

.radio-group {
    .radio-item {
        display: inline-block;
        padding: 0 16px;
    }
}
