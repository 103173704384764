.a-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 11px 66px;
    border: none;
    border-radius: $border-radius;
    font-family: $font-family-IBM-Plex-Sans;
    font-size: 16px;
    font-weight: 500;
    background-color: $color-blue;
    color: $color-white;
    cursor: pointer;
    box-shadow: $box-shadow-dark;

    svg {
        path {
            fill: $color-white;
        }
    }

    &--grey {
        background-color: $color-medium-grey;
    }

    &--green {
        background-color: $color-green;
    }

    &--orange {
        background-color: $color-orange;
    }

    &--white {
        color: $color-blue;
        background-color: $color-white;
    }

    &--isShort {
        padding: 11px 19px;
    }

    &--isRound {
        font-size: 20px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        width: 48px;
        padding: 0;
        width: 48px;
        height: 48px;
        border-radius: 50%;
    }

    &--isDisabled {
        background: $color-light-grey;
        color: $color-medium-grey;
        box-shadow: unset;
        cursor: none;
        pointer-events: none;
    }

    &--isFullWidth {
        width: 100%;
        padding: 11px 0;
    }
}
