.o-card {
  background: white;
  padding: 16px;
  border-radius: $border-radius;
  box-shadow: $box-shadow-light;

  &--dark {
    box-shadow: $box-shadow-dark;
  }
}

.o-card-top-border-only {
  background: white;
  padding: 16px;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;

}
