$spaceamounts: (4, 8, 16, 24, 32, 40, 48, 56, 64, 80, 112, 216); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
    @each $side in $sides {
        .u-margin-#{$side}-#{$space} {
            margin-#{$side}: #{$space}px !important;
        }

        .u-padding-#{$side}-#{$space} {
            padding-#{$side}: #{$space}px !important;
        }
    }
}
