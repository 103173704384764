.a-paragraph {
    font-family: $font-family-IBM-Plex-Sans;
    font-size: 16px;
    line-height: 24px;
    color: $color-dark-grey;
    margin: 0;
    padding: 0;

    &--grey {
        color: $color-medium-grey;
    }

    &--error {
        color: $color-red;
    }

    &--small {
        font-size: 14px;
    }
}


