.p-inputswitch .p-inputswitch-slider:before {
  width: 32px;
  height: 32px;
  box-shadow: 0 0 1px 0 rgba(41, 48, 51, 0.32), 0 1px 1px 0 rgba(41, 48, 51, 0.08), 0 2px 2px 0 rgba(41, 48, 51, 0.08), 0 4px 4px 0 rgba(41, 48, 51, 0.08), 0 8px 8px 0 rgba(41, 48, 51, 0.08);
  left: 0;
  margin-top: -1rem;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: $color-blue;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: none;
}
