.a-text-heading {
  font-size: 40px;
  color: $color-dark-grey;
  font-family: $font-family-IBM-Plex-Sans;
  font-weight: $font-weight-semiBold;
  line-height: 1.25;
  margin: 0;
  padding: 0;

  &--isSmall {
    font-size: 24px;
  }

  &--isTiny {
    font-size: 18px;
  }
}
